import React from "react";
import PaypalExpressBtn from "react-paypal-express-checkout";

export default class PaypalBtn extends React.Component {
  render() {
    const env = "sandbox";
    const currency = "USD";
    const client = {
      sandbox:
        "Aff79iLolcakJkh0KSRy_8ajMcuqcHAWtTEnFG3WkieFdtGHj9oQUHoKJdPZr6mKl0bFImBIRBDzfnvh",
      production:
        //"ARPydh_zCObxigPb8HWNPSGcVQ6BnUE8a2Rzd6xiBT3mV6SNoktJyewR1qqGtSOaIJo_TyIHGA2KpKcA"
        "AShqHJih3BuB3WTer3yj-ijtjA_6h3ce7VK7szNy8kQU2RxOxNnMJ0nQyMFsMnbPRU0AjTWr7EBhOF2o",
    };

    return (
      <PaypalExpressBtn
        env={env}
        client={client}
        currency={currency}
        total={this.props.total}
        onError={this.props.onError}
        onSuccess={this.props.onSuccess}
        onCancel={this.props.onCancel}
      />
    );
  }
}
